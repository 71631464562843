import { MenuItemType } from '../types/lookups';
import { generateHarvestYearOptions } from '../utils/generateHarvestYearOptions';

export const PAGE_SIZE_OPTIONS = [20, 50, 100];

export const PAGE_SIZE = 20;

export const PAGE = 0;

export const TOASTIFY_AUTO_CLOSE_TIME = 3000;

export const VH_FOR_PLOTS = '60vh';
export const VH_FOR_TABLES = '72vh';
export const TABLE_BOTTOM_PADDING = 0.5;

export enum ResourceNameEnum {
  PLOT = 'plot',
  COMPANY = 'company',
  USER = 'user',
  LEGALITY = 'legality',
  DDS = 'dds',
}

export enum PermissionTypes {
  FULL = 'full',
  VIEW = 'view',
  NO_ACCESS = 'none',
}

export enum DOCUMENT_TYPE_ENUM {
  LEGALITY_STATEMENT = 1,
  LAND_USE_RIGHTS = 2,
  ENV_PROTECTION = 3,
  THIRD_PARTY_RIGHTS = 4,
  LABOUR_RIGHTS = 5,
  HUMAN_RIGHTS = 6,
  TAX_ANTI_CORRUPTION = 7,
  INDIGENOUS_PEOPLES = 8,
  OTHER = 9,
}

export enum LEGALITY_TYPE_ENUM {
  MY_COMPANY = 0,
  COMPANIES_IN_MY_SUPPLY_CHIAN = 1,
}

export const PERMISSION_IDS = {
  PLOTS: {
    FULL: 1,
    READ: 2,
    NONE: 3,
  },
  COMPANY: {
    FULL: 4,
    READ: 5,
    NONE: 6,
  },
  USER: {
    FULL: 7,
    READ: 8,
    NONE: 9,
  },
  LEGALITY: {
    FULL: 10,
    READ: 11,
    NONE: 12,
  },
};

export const ROLE_IDS = {
  SUPER_SUPER_ADMIN: 1,
  SUPER_ADMIN: 2,
  ADMIN_VIEWER: 3,
  LICENSED_ADMIN: 4,
  LICENSED_MEMBER: 5,
  NON_LICENSED_MEMBER: 6,
};

export const ACCOUNT_STATUS_ID = {
  PENDING: 1,
  DISABLED: 2,
  ENABLED: 3,
};

export const API_TAGS = [
  'Auth',
  'Supplier',
  'User',
  'Plots',
  'Role',
  'Lookup',
  'Cadastral',
  'GeoJson',
  'S3',
] as const; //without 'as const' ts will no longer recognize api tags and throw error if you use some non existent tag

export const KML_TYPE = 'application/vnd.google-earth.kml+xml';
export const KMZ_TYPE = 'application/vnd.google-earth.kmz';
export const GEOJSON_TYPE = 'application/geo+json';
export const MAX_UPLOAD_SIZE_MB = 28;
export const MAX_UPLOAD_FILE_SIZE = MAX_UPLOAD_SIZE_MB * 1024 * 1024;

export const UPLOAD_DOCUMENTS_VALID_TYPES = [
  'image/bmp',
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/geo+json',
  'application/gzip',
  'text/html',
  'application/json',
  'image/jpeg',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/pdf',
  'image/png',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'image/vnd.adobe.photoshop',
  'application/rtf',
  'image/tiff',
  'text/plain',
  'image/webp',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/xml',
];

export const HARVEST_YEAR_OPTIONS: MenuItemType[] =
  generateHarvestYearOptions();

export enum ExportTypeEnum {
  TEMPLATE = 'template',
  GEOJSON = 'geojson',
  EXCEL = 'excel',
}
