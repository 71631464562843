import { api } from '.';

import {
  getCurrentSessionApi,
  getCurrentUserApi,
  signOutApi,
  updatePasswordApi,
} from './amplifyAuth';

const authApiEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUser: build.query({
      queryFn: getCurrentUserApi,
      providesTags: ['Auth'],
    }),
    getCurrentSession: build.query({
      queryFn: getCurrentSessionApi,
      providesTags: ['Auth'],
    }),
    signOutUser: build.mutation({
      queryFn: signOutApi,
      invalidatesTags: ['Auth'],
    }),
    updatePassword: build.mutation({
      queryFn: updatePasswordApi,
      invalidatesTags: ['Auth'],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetCurrentUserQuery,
  useSignOutUserMutation,
  useGetCurrentSessionQuery,
  useUpdatePasswordMutation,
} = authApiEndpoints;
