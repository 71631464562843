import { Navigate } from 'react-router-dom';

import {
  PermissionTypes,
  ResourceNameEnum,
  ROLE_IDS,
} from '../../../constants';
import { useAppSelector } from '../../../hooks/reduxStoreHooks';
import useGetTypeOfPermissionForResource from '../../../hooks/useGetTypeOfPermissionForResource';
import {
  AccountSettingsPage,
  AddPlots,
  CompanyDocuments,
  CompanyManagementPage,
  CreateCompany,
  CreateLegality,
  CreateUser,
  EditCompany,
  EditUser,
  HelpPage,
  ImportCadastralDataSerbia,
  LegalityPage,
  Page404,
  PlotDocuments,
  PlotsPage,
  SelectedCompanyPage,
  UpdatePasswordPage,
  UsersManagementPage,
} from '../../../routes/loadableRoutes';
import {
  PATH_APP_ACCOUNT_SETTINGS,
  PATH_APP_GEO_JSON_SERBIA,
  PATH_APP_HELP,
  PATH_APP_LEGALITY,
  PATH_APP_PLOTS,
  PATH_APP_PLOTS_MANAGEMENT,
  PATH_APP_SELECT_COMPANY,
  PATH_APP_UPDATE_PASSWORD,
  PATH_APP_USER_MANAGEMENT,
} from '../../../routes/paths';

export default function useGetAppRoutes() {
  const rootCompanyId = useAppSelector((state) => state.user.rootCompany?.id);
  const userAccount = useAppSelector((state) => state.user.userAccountData);

  const hasFullAccessToPlots = useGetTypeOfPermissionForResource({
    resource: ResourceNameEnum.PLOT,
    permissionType: PermissionTypes.FULL,
  });

  const hasFulLAccessToLegality = useGetTypeOfPermissionForResource({
    resource: ResourceNameEnum.LEGALITY,
    permissionType: PermissionTypes.FULL,
  });

  const hasViewAccessToLegality = useGetTypeOfPermissionForResource({
    resource: ResourceNameEnum.LEGALITY,
    permissionType: PermissionTypes.VIEW,
  });

  const hasFullAccessToCompanies = useGetTypeOfPermissionForResource({
    resource: ResourceNameEnum.COMPANY,
    permissionType: PermissionTypes.FULL,
  });

  const hasFullAccessToUsers = useGetTypeOfPermissionForResource({
    resource: ResourceNameEnum.USER,
    permissionType: PermissionTypes.FULL,
  });

  const hasNoAccessToPlots = useGetTypeOfPermissionForResource({
    resource: ResourceNameEnum.PLOT,
    permissionType: PermissionTypes.NO_ACCESS,
  });

  const hasNoAccessToCompanies = useGetTypeOfPermissionForResource({
    resource: ResourceNameEnum.COMPANY,
    permissionType: PermissionTypes.NO_ACCESS,
  });

  const hasNoAccessToUsers = useGetTypeOfPermissionForResource({
    resource: ResourceNameEnum.USER,
    permissionType: PermissionTypes.NO_ACCESS,
  });

  const hasNoAccessToImportCadastralData = !(
    userAccount?.roleId === ROLE_IDS.SUPER_SUPER_ADMIN ||
    userAccount?.roleId === ROLE_IDS.SUPER_ADMIN
  );

  const hasNoAccessToLegality = useGetTypeOfPermissionForResource({
    resource: ResourceNameEnum.LEGALITY,
    permissionType: PermissionTypes.NO_ACCESS,
  });

  // Plot routes
  let plotRoutes = [
    { path: PATH_APP_PLOTS.root, element: <PlotsPage /> },
    {
      element: <Navigate to={PATH_APP_PLOTS.root} replace />,
      index: true,
    },
  ];

  if (hasFullAccessToPlots) {
    plotRoutes.push({
      path: `${PATH_APP_PLOTS_MANAGEMENT.newPlot}`,
      element: <AddPlots />,
    });
  }

  // Legality routes
  let legalityRoutes = [
    { path: PATH_APP_LEGALITY.root, element: <LegalityPage /> },
  ];

  if (hasFulLAccessToLegality) {
    legalityRoutes.push({
      path: `${PATH_APP_LEGALITY.create}`,
      element: <CreateLegality />,
    });
  }
  if (hasFulLAccessToLegality || hasViewAccessToLegality) {
    legalityRoutes.push(
      {
        path: `${PATH_APP_LEGALITY.companyDocuments}/:companyId/:companyName/`,
        element: <CompanyDocuments />,
      },
      {
        path: `${PATH_APP_LEGALITY.plotDocuments}/:companyId/:companyName/:plotId`,
        element: <PlotDocuments />,
      }
    );
  }

  //Company routes
  let companyRoutes = [
    {
      path: PATH_APP_USER_MANAGEMENT.companiesOverview,
      element: <CompanyManagementPage />,
    },
  ];

  if (hasFullAccessToCompanies) {
    companyRoutes.push(
      {
        path: PATH_APP_USER_MANAGEMENT.newCompany,
        element: <CreateCompany />,
      },
      {
        path: `${PATH_APP_USER_MANAGEMENT.editCompany}/:id`,
        element: <EditCompany />,
      }
    );
  }

  // User routes
  let userRoutes = [
    {
      path: PATH_APP_USER_MANAGEMENT.usersOverview,
      element: <UsersManagementPage />,
    },
  ];

  if (hasFullAccessToUsers) {
    userRoutes.push(
      {
        path: PATH_APP_USER_MANAGEMENT.newUser,
        element: <CreateUser />,
      },
      {
        path: `${PATH_APP_USER_MANAGEMENT.editUser}/:companyId/:id/:parentCompanyId`,
        element: <EditUser />,
      }
    );
  }

  //GeoJson Serbia
  let geoJsonSerbiaRoutes = [];

  if (true) {
    geoJsonSerbiaRoutes.push({
      path: `${PATH_APP_GEO_JSON_SERBIA.root}`,
      element: <ImportCadastralDataSerbia />,
    });
  }

  //Rest routes
  const restRoutes = [
    {
      path: `${PATH_APP_ACCOUNT_SETTINGS.root}`,
      element: <AccountSettingsPage />,
    },
    {
      path: `${PATH_APP_UPDATE_PASSWORD.root}`,
      element: <UpdatePasswordPage />,
    },
    {
      path: `${PATH_APP_HELP.root}`,
      element: <HelpPage />,
    },
    { path: PATH_APP_SELECT_COMPANY.root, element: <SelectedCompanyPage /> },
    { path: `*`, element: <Page404 /> },
  ];

  if (!rootCompanyId) {
    //When MyCompany is not selected yet, or if user has logged in but is disabled in every company so he can not procede he will have this routes
    return [
      { path: PATH_APP_SELECT_COMPANY.root, element: <SelectedCompanyPage /> },
      { path: `*`, element: <Page404 /> },
    ];
  }

  const routes = [
    ...(!hasNoAccessToPlots ? plotRoutes : []),
    ...(!hasNoAccessToCompanies ? companyRoutes : []),
    ...(!hasNoAccessToUsers ? userRoutes : []),
    ...(!hasNoAccessToImportCadastralData ? geoJsonSerbiaRoutes : []),
    ...(!hasNoAccessToLegality ? legalityRoutes : []),
    ...restRoutes,
  ];

  return routes;
}
