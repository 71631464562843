import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AuthUser } from 'aws-amplify/auth';

import { LEGALITY_TYPE_ENUM } from '../constants';
import { CustomerCompany, MyCompany } from '../types/selectCompany';
import { BaseUserInfo, UserAccount } from '../types/userAccounts';

export type UserSlice = {
  cognitoUser: AuthUser | undefined;
  baseUserInfo: BaseUserInfo | null;
  userAccountData: UserAccount | null;
  baseUserIsLoading: boolean;
  rootCompany: MyCompany | null;
  parentCompany: CustomerCompany | null;
  legalityType: LEGALITY_TYPE_ENUM;
  harvestYear: number;
};

const initialState: UserSlice = {
  cognitoUser: undefined,
  baseUserInfo: null,
  rootCompany: null,
  parentCompany: null,
  userAccountData: null,
  baseUserIsLoading: true,
  legalityType: LEGALITY_TYPE_ENUM.MY_COMPANY,
  harvestYear: 0,
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    logInUser: (state, action: PayloadAction<AuthUser>) => {
      state.cognitoUser = action.payload;
    },
    logOutUser: (state) => {
      Object.assign(state, initialState);
    },
    setBaseUserInfo: (state, action: PayloadAction<BaseUserInfo>) => {
      state.baseUserInfo = action.payload;
    },
    setBaseUserInfoIsLoading: (state, action: PayloadAction<boolean>) => {
      state.baseUserIsLoading = action.payload;
    },
    setUserAccountData: (state, action: PayloadAction<UserAccount>) => {
      state.userAccountData = action.payload;
    },
    clearUserAccountData: (state) => {
      state.userAccountData = null;
    },
    setRootCompany: (state, action: PayloadAction<MyCompany | null>) => {
      state.rootCompany = action.payload;
    },
    setParentCompany: (
      state,
      action: PayloadAction<CustomerCompany | null>
    ) => {
      state.parentCompany = action.payload;
    },
    setLegalityType: (state, action: PayloadAction<LEGALITY_TYPE_ENUM>) => {
      state.legalityType = action.payload;
    },
    setHarvestYear: (state, action: PayloadAction<number>) => {
      state.harvestYear = action.payload;
    },
  },
});

export const {
  logInUser,
  logOutUser,
  setBaseUserInfo,
  setBaseUserInfoIsLoading,
  setRootCompany,
  setParentCompany,
  setUserAccountData,
  clearUserAccountData,
  setLegalityType,
  setHarvestYear,
} = userSlice.actions;

export default userSlice.reducer;
