import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { fetchAuthSession } from 'aws-amplify/auth';

import { API_TAGS } from '../constants';
import { DEFAULT_LANG_VALUE } from '../hooks/useGetSystemLangs';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: async (headers) => {
    const session = await fetchAuthSession();

    const accessToken = session.tokens?.accessToken.toString();
    const userPreferredLanguage =
      localStorage.getItem('lang') || DEFAULT_LANG_VALUE;

    headers.set('Accept-Language', userPreferredLanguage);

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }

    if (!accessToken) {
      throw new Error(`NO ACCESS_TOKEN - ERROR IN src/api/index.ts `);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithRetry,
  tagTypes: [...API_TAGS],
  endpoints: () => ({}),
});
