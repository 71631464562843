import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../hooks/reduxStoreHooks';

export default function HarvestYearHeading() {
  const { t } = useTranslation();

  const harvestYear = useAppSelector((state) => state.user.harvestYear);

  return (
    <Stack direction="row">
      <Typography variant="subtitle2">
        {`${t('table.harvestYear')}:`}&nbsp;
      </Typography>

      <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
        {harvestYear}
      </Typography>
    </Stack>
  );
}
