import { Hub } from 'aws-amplify/utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { signOutApi } from '.';
import { api } from '..';
import { useAppDispatch } from '../../hooks/reduxStoreHooks';
import useFetchAndSetBaseUserInfo from '../../hooks/useFetchAndSetBaseUserInfo';
import { PATH_APP_PLOTS, PATH_AUTH } from '../../routes/paths';
import { logInUser, logOutUser } from '../../storeSlices/userSlice';

const AuthListener = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useFetchAndSetBaseUserInfo();

  //Listens for cognito auth events and dispatches actions base on the event
  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signedIn':
          dispatch(logInUser(payload.data));
          navigate(PATH_APP_PLOTS.root);
          break;
        case 'tokenRefresh_failure':
          signOutApi();
          break;
        case 'signedOut':
          dispatch(logOutUser());
          dispatch(api.util.resetApiState());
          navigate(PATH_AUTH.login);
          break;
      }
    });
    return () => unsubscribe();
  }, [dispatch, navigate]);

  return null;
};

export default AuthListener;
