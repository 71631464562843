import { useTranslation } from 'react-i18next';

import { useAppSelector } from './reduxStoreHooks';

export default function useGetCompanyLevelTranslation(companyLvlId?: number) {
  const { t } = useTranslation();

  const rootCompany = useAppSelector((state) => state.user.rootCompany);

  const switchCase = companyLvlId ? companyLvlId : rootCompany?.companyLevelId;

  switch (switchCase) {
    case 1:
      return t('companyLevel.superSuperAdmin');
    case 2:
      return t('companyLevel.superAdmin');
    case 3:
      return t('companyLevel.licensedCompany');
    case 4:
      return t('companyLevel.nonLicensedCompany');
    case 5:
      return t('companyLevel.nonLicensedCompanyVirtual');

    default:
      return 'N/A';
  }
}
