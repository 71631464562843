import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Button,
  Divider,
  IconButton,
  Stack,
  Toolbar,
} from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../../hooks/reduxStoreHooks';
import useGetNavItems from '../../../hooks/useGetNavItems';
import useResponsive from '../../../hooks/useResponsive';
import { PATH_APP_SELECT_COMPANY } from '../../../routes/paths';
import AccountPopover from '../../AccountPopover';
import CustomContainer from '../../CustomContainer';
import HarvestYearHeading from '../../HarvestYearHeading';
import Logo from '../../Logo';
import RootCompanyHeading from '../../RootCompanyHeading';
import SupplyChainHeading from '../../SupplyChainHeading';
import NavItemButton from '../NavItemButton';

export default function MobileNav() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const NavItems = useGetNavItems();

  const rootCompanyId = useAppSelector((state) => state.user.rootCompany?.id);

  const isMobile = useResponsive('down', 'sm');

  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ width: '100%' }}>
      <AppBar position="static" color="inherit">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>

          <Stack
            direction={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Stack sx={{ py: rootCompanyId ? 2 : 0 }}>
              {rootCompanyId && (
                <>
                  <RootCompanyHeading />
                  <SupplyChainHeading />
                  <HarvestYearHeading />
                </>
              )}
            </Stack>

            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              justifyContent={isMobile ? 'auto' : 'flex-end'}
              sx={{
                py: { xs: 1, md: 0 },
                width: { xs: '100%', sm: 'auto' },
              }}
            >
              {rootCompanyId && (
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => navigate(PATH_APP_SELECT_COMPANY.root)}
                >
                  {t('general.selectCompany')}
                </Button>
              )}

              <AccountPopover />
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>

      <Drawer anchor={'left'} open={open} onClose={() => setOpen(false)}>
        <Stack alignItems="center" sx={{ pt: 1 }}>
          <Logo disabledLink />

          <Divider sx={{ width: '100%', pt: 1.5 }} />

          <List sx={{ px: 1 }}>
            {NavItems.map((navItem) => (
              <Stack key={navItem.label}>
                <NavItemButton
                  navItem={navItem}
                  open={open}
                  setClose={() => setOpen(false)}
                />
                {navItem.addDividerAfter && <Divider sx={{ my: 2 }} />}
              </Stack>
            ))}
          </List>
        </Stack>
      </Drawer>
      <Box
        component="main"
        data-testid="MobileNav"
        sx={{ overflow: 'auto', pb: 5 }}
      >
        <CustomContainer>
          <Outlet />
        </CustomContainer>
      </Box>
    </Box>
  );
}
