import extractPermissionId from '../utils/extractPermissionId';
import { useAppSelector } from './reduxStoreHooks';

export default function useGetPermissionsIds() {
  const userAccountData = useAppSelector((state) => state.user.userAccountData);
  const permissionsIds = extractPermissionId({
    permissions: userAccountData?.permissions || [],
  });

  return permissionsIds;
}
