import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import {
  fetchAuthSession,
  getCurrentUser,
  signOut,
  updatePassword,
} from 'aws-amplify/auth';

export async function signOutApi() {
  try {
    const result = await signOut({ global: true });
    return { data: result };
  } catch (error) {
    return {
      error: error as FetchBaseQueryError,
    };
  }
}

export async function getCurrentUserApi() {
  try {
    const user = await getCurrentUser();
    return { data: user };
  } catch (error) {
    return {
      error: error as FetchBaseQueryError,
    };
  }
}

export async function getCurrentSessionApi() {
  try {
    const session = await fetchAuthSession();
    return { data: session };
  } catch (error) {
    return {
      error: error as FetchBaseQueryError,
    };
  }
}

export async function updatePasswordApi({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}) {
  try {
    await updatePassword({ oldPassword, newPassword });
    return { data: { message: 'Password successfully updated' } };
  } catch (error: any) {
    const errorName: string =
      error.name ||
      'An unknown error occurred - this is error from amplifyAuth/index.tsx';
    const fetchBaseQueryError: FetchBaseQueryError = {
      status: 'CUSTOM_ERROR',
      error: errorName,
    };
    return { error: fetchBaseQueryError };
  }
}
