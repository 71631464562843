import {
  PERMISSION_IDS,
  PermissionTypes,
  ResourceNameEnum,
} from '../constants';
import useGetPermissionsIds from './useGetPermissionsIds';

type Props = {
  resource: ResourceNameEnum;
  permissionType: PermissionTypes;
};

export default function useGetTypeOfPermissionForResource({
  resource,
  permissionType,
}: Props) {
  const permissionsIds = useGetPermissionsIds();

  switch (resource) {
    case ResourceNameEnum.PLOT:
      if (permissionType === PermissionTypes.FULL) {
        return permissionsIds.plot === PERMISSION_IDS.PLOTS.FULL;
      }
      if (permissionType === PermissionTypes.VIEW) {
        return permissionsIds.plot === PERMISSION_IDS.PLOTS.READ;
      }
      if (permissionType === PermissionTypes.NO_ACCESS) {
        return permissionsIds.plot === PERMISSION_IDS.PLOTS.NONE;
      }
      break;

    case ResourceNameEnum.COMPANY:
      if (permissionType === PermissionTypes.FULL) {
        return permissionsIds.company === PERMISSION_IDS.COMPANY.FULL;
      }
      if (permissionType === PermissionTypes.VIEW) {
        return permissionsIds.company === PERMISSION_IDS.COMPANY.READ;
      }
      if (permissionType === PermissionTypes.NO_ACCESS) {
        return permissionsIds.company === PERMISSION_IDS.COMPANY.NONE;
      }
      break;

    case ResourceNameEnum.USER:
      if (permissionType === PermissionTypes.FULL) {
        return permissionsIds.user === PERMISSION_IDS.USER.FULL;
      }
      if (permissionType === PermissionTypes.VIEW) {
        return permissionsIds.user === PERMISSION_IDS.USER.READ;
      }
      if (permissionType === PermissionTypes.NO_ACCESS) {
        return permissionsIds.user === PERMISSION_IDS.USER.NONE;
      }
      break;

    case ResourceNameEnum.LEGALITY:
      if (permissionType === PermissionTypes.FULL) {
        return permissionsIds.legality === PERMISSION_IDS.LEGALITY.FULL;
      }
      if (permissionType === PermissionTypes.VIEW) {
        return permissionsIds.legality === PERMISSION_IDS.LEGALITY.READ;
      }
      if (permissionType === PermissionTypes.NO_ACCESS) {
        return permissionsIds.legality === PERMISSION_IDS.LEGALITY.NONE;
      }
      break;

    default:
      return false;
  }
  return false;
}
