import { ResourceNameEnum } from '../constants';
import { Permission } from '../types/lookups';

type Props = {
  permissions: Permission[];
};

type PermissionIds = {
  [key in ResourceNameEnum]: number | null;
};

export default function extractPermissionId({
  permissions,
}: Props): PermissionIds {
  const result: PermissionIds = {
    [ResourceNameEnum.PLOT]: null,
    [ResourceNameEnum.COMPANY]: null,
    [ResourceNameEnum.USER]: null,
    [ResourceNameEnum.LEGALITY]: null,
    [ResourceNameEnum.DDS]: null,
  };

  Object.values(ResourceNameEnum).forEach((resource) => {
    const match = permissions.find((permission) =>
      permission.name.includes(resource)
    );
    if (match) {
      result[resource] = match.id;
    }
  });

  return result;
}
