import { Box, BoxProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import CropInsightsLogo from '../assets/CropInsightsLogo.png';
import LogoImage from '../assets/LogoImage.png';

interface Props extends BoxProps {
  disabledLink?: boolean;
  width?: number;
  height?: number;
  isSmall?: boolean;
}

export default function Logo({
  isSmall = false,
  disabledLink = false,
  height = 55,
  width = 80,
  sx,
}: Props) {
  const logo = !isSmall ? (
    <Box
      component='img'
      sx={{
        borderRadius: 0.5,
        width,
        height,
        transition: 'opacity 0.3s ease',
        '&:hover': {
          opacity: disabledLink ? 1 : 0.7,
        },
        ...sx,
      }}
      src={CropInsightsLogo}
      alt='companyLogo'
    />
  ) : (
    <Box
      component='img'
      sx={{
        borderRadius: 0.5,
        width: 50,
        height: 70,
        transition: 'opacity 0.3s ease',
        '&:hover': {
          opacity: disabledLink ? 1 : 0.7,
        },
        ...sx,
      }}
      src={LogoImage}
      alt='companyLogo'
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <RouterLink style={{ paddingTop: 3.5 }} to='/'>
      {logo}
    </RouterLink>
  );
}
