import { MenuItemType } from '../types/lookups';

export function generateHarvestYearOptions(): MenuItemType[] {
  const startYear = 2024;
  const currentYear = new Date().getFullYear();
  const endYear = currentYear + 1;

  // Generate an array of years and map it directly to MenuItemType objects
  return Array.from({ length: endYear - startYear + 1 }, (_, index) => {
    const year = startYear + index;
    return {
      key: year,
      value: year,
      menuItemLabel: year.toString(),
    };
  });
}
