import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../hooks/reduxStoreHooks';
import useIsUserFromAdminOrDevCompany from '../hooks/useIsUserFromAdminOrDevCompany';

export default function SupplyChainHeading() {
  const { t } = useTranslation();

  const parentCompany = useAppSelector((state) => state.user.parentCompany);

  const isAdminOrDev = useIsUserFromAdminOrDevCompany();

  return (
    <>
      {!isAdminOrDev && (
        <Stack direction='row'>
          <Typography variant='subtitle2'>
            {`${t('general.customer')}:`}&nbsp;
          </Typography>

          <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
            {`${parentCompany?.companyName} `}
          </Typography>
        </Stack>
      )}
    </>
  );
}
